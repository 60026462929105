import { useState, createContext, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { themeCreator } from './base';

export const ThemeContext = createContext((_themeName) => {});

const ThemeProviderWrapper = (props) => {
  const [themeName, _setThemeName] = useState('LightTheme');

  useEffect(() => {
    const curThemeName =
      window.localStorage.getItem('appTheme') || 'LightTheme';
    _setThemeName(curThemeName);
  }, []);

  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    window.localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
      <ThemeContext.Provider value={setThemeName}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
